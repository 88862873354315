/* Contenitore dell'immagine */
.hero-section-blockchain {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
  
  .hero-image-blockchain {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-text-blockchain {
    position: absolute;
    top: 15%;
    left: 70%;
    width: 35%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 48px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .hero-section-blockchain::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    clip-path: polygon(0 85%, 75% 100%, 100% 85%, 100% 100%, 0 100%);
    z-index: 1;
  }
  
  /*----------------------------------------*/

  .container-blockchain01 {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
  }

  .container-blockchain01 h2 {
    font-size: 45px;
    margin-bottom: 400px;
  }

  /* Tablet: 768px */
@media screen and (max-width: 768px) {
  .hero-section-blockchain {
      height: 400px;
  }

  .hero-text-blockchain {
      font-size: 35px;
      top: 20%;
      left: 70%;
  }

  .container-blockchain01 {
      width: 85vw;
  }

  .container-blockchain01 h2 {
      font-size: 35px;
  }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
  .hero-section-blockchain {
      height: 300px;
  }

  .hero-text-blockchain {
      font-size: 25px;
      top: 25%;
      left: 70%;
  }

  .container-blockchain01 {
      width: 95vw;
  }

  .container-blockchain01 h2 {
      font-size: 28px;
      margin-bottom: 200px;
  }
}