/* Contenitore dell'immagine */
.hero-section-iot {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
  
  .hero-image-iot {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-text-iot {
    position: absolute;
    top: 15%;
    left: 70%;
    width: 35%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .hero-section-iot::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    clip-path: polygon(0 85%, 75% 100%, 100% 85%, 100% 100%, 0 100%);
    z-index: 1;
  }

  /*--------------------------------------------*/

  .container-p-iot {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
  }

  .container-p-iot h2 {
    font-size: 45px;
  }

  .container-p-iot p {
    font-size: 18px;
  }

  .container-p-iot hr {
    width: 75vw;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  /*--------------------------------------------*/
  
  .container-tot-card-iot {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .container-tot-card-iot h2 {
    font-size: 45px;
  }

  .container-tot-card-iot p {
    font-size: 18px;
    width: 80%;
    margin: 10px;
  }

  .container-card-iot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-iot {
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-iot img {
    width: 70%;
  }

  .container-btn-contattaci-iot {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(230, 187, 95);
    width: 75vw;
    margin: 50px;
    padding-bottom: 20px;
    text-align: center;
  }

  .container-btn-contattaci-iot h2 {
    font-size: 35px;
    margin: 10px;
  }

  .container-btn-contattaci-iot button {
    padding: 15px 30px 15px 30px;
    font-size: 20px;
    color: white;
    background-color: rgb(93, 157, 209);
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }

  .container-btn-contattaci-iot button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    transform: scale(1.1);
  }

  /* Tablet: 768px */
@media screen and (max-width: 768px) {
  .hero-section-iot {
      height: 400px;
  }

  .hero-text-iot {
      font-size: 30px;
      top: 20%;
      left: 70%;
  }

  .container-p-iot {
      width: 90vw;
  }

  .container-p-iot h2 {
      font-size: 35px;
  }

  .container-p-iot p {
      font-size: 16px;
  }

  .container-tot-card-iot h2 {
      font-size: 35px;
  }

  .container-tot-card-iot p {
      font-size: 16px;
  }

  .card-iot {
      width: 45vw;
      padding: 10px;
  }

  .container-btn-contattaci-iot {
      width: 90vw;
      padding-bottom: 15px;
  }

  .container-btn-contattaci-iot h2 {
      font-size: 30px;
  }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
  .hero-section-iot {
      height: 300px;
  }

  .hero-text-iot {
      font-size: 20px;
      top: 25%;
      left: 70%;
  }

  .container-p-iot {
      width: 95vw;
  }

  .container-p-iot h2 {
      font-size: 28px;
  }

  .container-p-iot p {
      font-size: 14px;
  }

  .container-tot-card-iot h2 {
      font-size: 28px;
  }

  .container-tot-card-iot p {
      font-size: 14px;
  }

  .card-iot {
      width: 90vw;
      padding: 10px;
  }

  .container-btn-contattaci-iot {
      width: 95vw;
      padding-bottom: 10px;
  }

  .container-btn-contattaci-iot h2 {
      font-size: 25px;
  }

  .container-btn-contattaci-iot button {
      width: 90%;
      font-size: 18px;
  }
}