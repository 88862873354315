/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}


.header.scrolled {
    position: fixed;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


#logo {
    width: 110px;
    transition: width 0.3s ease;
}


.header.scrolled #logo {
    width: 90px;
}


.nav {
    display: flex;
    gap: 30px;
}


.nav-item a {
    font-size: 18px;
    margin-right: 100px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: color 0.3s ease, transform 0.3s ease;
}


.nav-item a:hover {
    color: rgb(55, 185, 236);
    transform: scale(1.05);
}


/* Tablet: 768px */
@media screen and (max-width: 768px) {
    .header {
        padding: 15px 30px;
    }

    #logo {
        width: 80px;
    }

    .nav {
        gap: 20px;
    }

    .nav-item a {
        font-size: 16px;
        margin-right: 50px;
    }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
    .header {
        padding: 20px 30px;
    }

    #logo {
        width: 70px;
        margin-bottom: 10px;
    }

    .nav {
        flex-direction: column;
        gap: 10px;
    }

    .nav-item a {
        font-size: 14px;
        margin: 0;
    }
}