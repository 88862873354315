/* Contenitore dell'immagine */
.hero-section-software {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
  
  .hero-image-software {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-text-software {
    position: absolute;
    top: 15%;
    left: 70%;
    width: 35%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 48px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .hero-section-software::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    clip-path: polygon(0 85%, 75% 100%, 100% 85%, 100% 100%, 0 100%);
    z-index: 1;
  }

  /*--------------------------------------------------*/

  .container-software01 {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50vw;
    margin-left: 12%;
    margin-right: auto;
  }

  .container-software01 h2 {
    font-size: 45px;
  }

  .container-software01 p {
    font-size: 20px;
    margin: 10px;
  }
  
  /*--------------------------------------------------*/

  .container-software02 hr {
    width: 75vw;
    margin-top: 90px;
    margin-bottom: 20px;
  }

  .container-software02 {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
  }

  .container-software02 h2 {
    font-size: 45px;
  }

  .container-software02 p {
    font-size: 18px;
    margin: 10px;
  }

  /*--------------------------------------------------*/

  .container-software03 hr {
    margin-top: 90px;
    margin-bottom: 20px;
    width: 50vw;
  }

  .container-software03 {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50vw;
    margin-left: 12%;
    margin-right: auto;
  }

  .container-software03 h2 {
    font-size: 45px;
  }

  .container-software03 li {
    font-size: 20px;
    margin: 0px;
  }


  /*--------------------------------------------------*/

  .container-software04 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(230, 187, 95);
    width: 75vw;
    margin: 50px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-software04 h2 {
    font-size: 35px;
    margin: 30px;
  }

  .container-software04 button {
    padding: 15px 30px 15px 30px;
    font-size: 20px;
    background-color: rgb(93, 157, 209);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }

  .container-software04 button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    transform: scale(1.1);
  }

  /* Tablet: 768px */
@media screen and (max-width: 768px) {
  .hero-section-software {
      height: 400px;
  }

  .hero-text-software {
      font-size: 35px;
      top: 20%;
      left: 70%;
  }

  .container-software01,
  .container-software02,
  .container-software03 {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;
  }

  .container-software01 h2,
  .container-software02 h2,
  .container-software03 h2 {
      font-size: 35px;
  }

  .container-software01 p,
  .container-software02 p,
  .container-software03 li {
      font-size: 16px;
  }

  .container-software04 {
      width: 90vw;
      text-align: center;
  }

  .container-software04 h2 {
      font-size: 30px;
  }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
  .hero-section-software {
      height: 300px;
  }

  .hero-text-software {
      font-size: 25px;
      top: 25%;
      left: 70%;
  }

  .container-software01,
  .container-software02,
  .container-software03 {
      width: 95vw;
      margin-left: auto;
      margin-right: auto;
  }

  .container-software01 h2,
  .container-software02 h2,
  .container-software03 h2 {
      font-size: 28px;
  }

  .container-software01 p,
  .container-software02 p,
  .container-software03 li {
      font-size: 14px;
  }

  .container-software04 {
      width: 95vw;
  }

  .container-software04 h2 {
      font-size: 25px;
  }

  .container-software04 button {
      width: 90%;
      font-size: 18px;
  }
}