/* BANNER COOKIES */

.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 55%;
  background-color: white;
  border: 1px solid black;
  padding: 15px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}

.cookie-banner p {
  margin: 3px;
  font-size: 15px;
}

.cookie-banner a {
  color: #007bff;
  text-decoration: underline;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.cookie-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.cookie-buttons button {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid black;
  background-color: yellow;
  cursor: pointer;
}

.accept-button,
.reject-button,
.customize-button {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid black;
  background-color: yellow;
  cursor: pointer;
}

.reopen-icon {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgb(63, 196, 63);
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.reopen-icon:hover {
  transform: scale(1.2);
  background-color: rgb(44, 219, 44);
}

/* COOKIE POLICY */

.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.policy-modal-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow-y: auto;
}

.container-policy-x {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.close-cookiePolicy-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.policy-modal h2 {
  font-size: 35px;
  margin-bottom: 10px;
}

.policy-modal h3 {
  font-size: 21px;
  margin-bottom: 10px;
}

.policy-modal p {
  font-size: 15px;
  line-height: 1.6;
}

.policy-modal a {
  color: #007bff;
  text-decoration: underline;
  font-size: 15px;
}

.policy-modal ul li {
  font-size: 15px;
}

.btn-modificaConsenso {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
}

/* IMPOSTAZIONI DEI COOKIES */

.cookie-settings {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 40%;
  background-color: #fff;
  padding: 20px;
  border: 5px solid #969393;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  z-index: 1000;
}

.cookie-option {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #e7e9eb;
}

.container-span-btn-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cookie-option-label {
  font-weight: bold;
  flex: 1;
}

.cookie-option-description {
  flex: 2;
  text-align: left;
  font-size: 14px;
  color: #555;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

input:disabled + .slider {
  background-color: #9ae2ba;
}

.save-button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #76c47b;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.save-button:hover {
  background-color: #679e69;
}

.details-toggle-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #67696b;
  margin-left: 10px;
}

.cookie-details {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  color: #555;
}

/* Tablet: 768px */
@media screen and (max-width: 768px) {
  .cookie-banner {
    width: 80%;
  }

  .cookie-banner p {
    font-size: 12px;
  }

  .policy-modal-content {
    width: 90%;
  }

  .cookie-settings {
    width: 50%;
  }

  .cookie-option-label {
    margin-bottom: 5px;
  }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
  .cookie-banner {
    width: 90%;
    padding: 15px;
  }

  .close-button {
    font-size: 14px;
  }

  .cookie-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .policy-modal-content {
    width: 95%;
    padding: 15px;
  }

  .container-policy-x h2 {
    font-size: 20px;
  }

  .cookie-settings {
    width: 50%;
  }

  .cookie-option {
    margin-bottom: 15px;
  }

  .cookie-option-label {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .cookie-option-description {
    font-size: 12px;
  }

  .save-button {
    width: 100%;
    font-size: 14px;
  }

  .reopen-icon {
    bottom: 20px;
    left: 20px;
    font-size: 20px;
  }
}
