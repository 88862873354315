
.App {
    max-width: 2100px;
    margin: 0 auto;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

