.container-footer {
    display: flex;
    background-color: black;
    color: white;
    justify-content: center;
    padding: 0px;
}

.container-footer div {
    text-align: center;
    border: 1px solid rgb(75, 74, 74);
    width: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.container-footer p {
    margin-top: 8px;
    margin-bottom: 0px;
    color: rgb(175, 175, 175);
    font-size: 16px;
}

.container-icon-facebook-twitter {
    background-color: rgb(16, 112, 150);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Tablet: 768px */
@media screen and (max-width: 768px) {
    .container-footer {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .container-footer div {
        width: 80%;
        margin-bottom: 20px;
        border: none;
    }

    .container-icon-facebook-twitter {
        margin-top: 20px;
    }
}

/* Mobile: 425px */
@media screen and (max-width: 425px) {
    .container-footer {
        padding: 15px;
    }

    .container-footer div {
        width: 100%;
        padding: 10px;
        border: none;
    }

    .container-footer p {
        font-size: 16px;
    }

    .container-icon-facebook-twitter {
        margin-top: 15px;
    }

    svg {
        width: 25px;
        height: 25px;
    }
}