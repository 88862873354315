/* Immagine principale */
.main-content {
  width: 100%;
  height: 100%;
  position: relative;
}

#immaginePrincipale {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Testo principale */
.main-text {
  position: absolute;
  width: 50%;
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  color: black;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  font-style: italic;
}

/* Contenitore delle card */
.cards-container {
  display: flex;
  justify-content: center;
}

/* Card */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 25%;
  text-align: center;
  transition: transform 0.3s ease;
}

.card-icon {
  padding: 30px;
  margin: 30px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Bottoni delle icone con colori diversi */
.card-icon-blue {
  background-color: rgb(64, 181, 248);
}

.card-icon-green {
  background-color: rgb(67, 206, 67);
}

.card-icon-yellow {
  background-color: rgb(248, 201, 72);
}

.card-icon-purple {
  background-color: rgb(161, 65, 161);
}

/* Effetto hover per tutti i bottoni */
.card-icon:hover {
  transform: scale(1.1);
  background-color: black;
}

/* Titolo della card */
.card-title {
  font-size: 1.3em;
  margin: 16px 0;
  margin-top: 0px;
}

/* Descrizione della card */
.card-description {
  font-size: 18px;
  padding: 0 16px;
  color: #666;
}

/* Bottone della card */
.card-btn {
  color: #fff;
  padding: 8px 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.card-btn:hover {
    transform: scale(1.1);
  }

a {
  text-decoration: none;
  color: #fff;
}

/* Bottoni delle icone con colori diversi */
.card-btn-blue {
  background-color: rgb(64, 181, 248);
}

.card-btn-green {
  background-color: rgb(67, 206, 67);
}

.card-btn-yellow {
  background-color: rgb(248, 201, 72);
}

.card-btn-purple {
  background-color: rgb(161, 65, 161);
}

.card-btn:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* PARTE CYBER SECUTITY */

/* Contenitore principale */
.container-security {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  gap: 40px;
  padding: 40px;
  padding-top: 70px;
  margin-top: 100px;
  background-color: #ececec;
  padding-bottom: 100px;
}

#container-dati-sicuri {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

/* Immagine */
.container-security img {
  max-width: 40%;
  box-shadow: 0 8px 16px rgba(121, 121, 121, 0.4);
}

/* Testo */
.container-security h2 {
  font-size: 3em;
  margin: 0px;
  color: #333;
}

.container-security p {
  font-size: 1.2em;
  color: #555;
  line-height: 1.6;
}

/* Bottone */
.container-security button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: rgb(55, 185, 236);
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.container-security button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

/* PARTE IOT (INTERNET OF THINGS) */

/* Contenitore principale */
.container-IOT {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  gap: 40px;
  padding: 40px;
  padding-top: 70px;
  background-color: #ececec;
  padding-bottom: 100px;
}

#container-soluzioni-intelligenti {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

/* Immagine */
.container-IOT img {
  max-width: 40%;
  box-shadow: 0 8px 16px rgba(121, 121, 121, 0.4);
}

/* Testo */
.container-IOT h2 {
  font-size: 3em;
  margin: 0px;
  color: #333;
}

.container-IOT p {
  font-size: 1.2em;
  color: #555;
  line-height: 1.6;
}

/* Bottone */
.container-IOT button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: rgb(55, 185, 236);
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.container-IOT button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

/* PARTE SOFTWARE SU MISURA */

/* Contenitore principale */
.container-software {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  gap: 40px;
  padding: 40px;
  padding-top: 70px;
  background-color: #192144;
}

#container-software-misura {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

/* Immagine */
.container-software img {
  max-width: 40%;
  box-shadow: 0 8px 16px rgba(121, 121, 121, 0.4);
}

/* Testo */
.container-software h2 {
  font-size: 3em;
  margin: 0px;
  color: white;
}

.container-software p {
  font-size: 1.2em;
  color: white;
  line-height: 1.6;
}

/* Bottone */
.container-software button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background-color: rgb(55, 185, 236);
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.container-software button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

/* PARTE CONSULENZA */

.container-consulenza {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px;
  background-color: #ececec;
  margin-bottom: 10px;
}

.container-consulenza h2 {
  font-size: 40px;
  margin-top: 0px;
}

.container-consulenza p {
  text-align: center;
  font-size: 17px;
  margin: 0px;
}

.btn-consulenza {
  width: 300px;
  font-size: 20px;
  padding: 15px;
  border-radius: 15px;
  color: rgb(55, 185, 236);
  border-color: rgb(55, 185, 236);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  display: inline-block;
  text-decoration: none;
}

.btn-consulenza:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  color: white;
  background-color: rgb(55, 185, 236);
}

/* PARTE VIRTUAL LOGISTIC COMPANY */

.container-virtual-logistic-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ececec;
}

.container-virtual-logistic-company h2 {
  font-size: 50px;
}

.container-virtual-logistic-company p {
  font-size: 20px;
}

.container-virtual-logistic-company img {
  width: 30vw;
  margin: 20px;
}

/* PARTE STMP */

.container-stmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.container-stmp h2 {
  font-size: 50px;
}

.container-stmp img {
  width: 400px;
}

.container-stmp p {
  font-size: 23px;
  padding-left: 20px;
  padding-right: 20px;
}

.container-stmp hr {
  width: 90%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.card-stmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.card-stmp img {
  width: 100%;
}

/* Responsive per tablet (max-width: 768px) */
@media screen and (min-width: 425px) and (max-width: 768px) {
  /* Immagine principale */
  #immaginePrincipale {
    height: 70vh;
  }

  /* Testo principale */
  .main-text {
    font-size: 18px;
    top: 25%;
    left: 25%;
  }

  /* Contenitore delle card */
  .cards-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  /* Card */
  .card {
    width: 45%;
    margin-bottom: 20px;
  }

  /* Sezioni specifiche */
  .container-security,
  .container-IOT,
  .container-software {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 30px;
    height: auto;
  }

  .container-security img,
  .container-IOT img,
  .container-software img {
    max-width: 80%;
    margin-bottom: 20px;
  }

  #container-dati-sicuri,
  #container-soluzioni-intelligenti,
  #container-software-misura {
    width: 90%;
  }

  .container-security button,
  .container-IOT button,
  .container-software button {
    margin-left: 0;
  }

  .container-virtual-logistic-company {
    text-align: center;
    padding: 20px;
  }

  .container-virtual-logistic-company img {
    width: 50vw;
  }

  /* Sezione consulenza */
  .container-consulenza {
    padding: 60px;
  }
}

/* Responsive per dispositivi mobili (max-width: 425px) */
@media screen and (max-width: 426px) {
  #immaginePrincipale {
    height: 60vh;
  }

  .main-text {
    font-size: 10px;
    top: 25%;
    left: 25%;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 85%;
    margin-bottom: 20px;
  }

  .container-security,
  .container-IOT,
  .container-software {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: auto;
  }

  .container-security img,
  .container-IOT img,
  .container-software img {
    max-width: 90%;
    margin-bottom: 20px;
  }

  #container-dati-sicuri,
  #container-soluzioni-intelligenti,
  #container-software-misura {
    width: 100%;
    margin: 0;
  }

  #container-dati-sicuri h2,
  #container-soluzioni-intelligenti h2,
  #container-software-misura h2 {
    font-size: 30px;
  }

  .container-security button,
  .container-IOT button,
  .container-software button {
    margin-left: 0;
  }

  .container-consulenza h2,
  .container-virtual-logistic-company h2,
  .container-stmp h2 {
    font-size: 30px;
  }

  .container-consulenza {
    padding: 40px;
    text-align: center;
  }

  .container-consulenza a {
    width: 70%;
  }

  .container-virtual-logistic-company {
    text-align: center;
    padding: 20px;
  }

  .container-virtual-logistic-company p {
    font-size: 15px;
  }

  .container-virtual-logistic-company img {
    width: 50vw;
  }

  .card-stmp {
    width: 80%;
  }

  .card-stmp p {
    font-size: 18px;
  }
}
